/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';

export default class NotFound extends Component {
  render() {
    return (
      <div className="not-found">
        <div className="page-container">
          <h1>404</h1>
          <h3>NotFound</h3>
        </div>
      </div>
    );
  }
}
