/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import logo from '../images/logo.webp';

export default class Footer extends Component {
  render() {
    return (
      <div>
        <footer className="footer">
          <div className="logo">
            <img src={logo} alt="" />
          </div>

          <div className="copyright">
            ©Developed by
            {' '}
            <br />
            {' '}
            Marcelo Vieira | Adson Henrique
          </div>

        </footer>
      </div>
    );
  }
}
